<template>
  <a-row :gutter="[12, 12]" class="mt-5 org-contain-flow">
    <ul class="contain">
      <li><a href="https://www.renrenlv.com.cn/gsps/qa/t/qa_1_t.html?channelNo=gslzQ001" target="_blank"><i class="icon1"></i><span>智能咨询</span><p>智能引导 方便快捷</p></a></li>
      <li href="javasript:value(0)" target="_blank" @click="() => {
          this.$router.push('/adviceadd')}"><a ><i class="icon2"></i><span>留言咨询</span><p>人工客服 快速解答</p></a></li>
      <li><router-link to="/notarization" target="_blank"><i class="icon3"></i><span>预约办理</span><p>在线预约 及时受理</p></router-link></li>
      <li><a href="https://api.egongzheng.com/thirdTransfer.htm?regionId=p28&appId=gs12348&version=v2&ticket=ST-168911-JFF7Sg5qqfrgowFJYsbT-cas01.example.org" target="_blank"><i class="icon4"></i><span>在线办理</span><p>直接办理 自助服务</p></a></li>
    </ul>
  </a-row>
</template>
<script>
export default {
  name: 'notary',
  components: {
  },
  data () {
    return {}
  }
}
</script>
