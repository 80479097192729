<template>
  <Page :title="'公证服务'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">公证服务</h1>
          <p class="desc">
            公证是公证机构根据自然人、法人或者其他组织的申请，依照法定程序对民事法律行为、有法律意义的事实和文书的真实性、合法性予以证明的活动。公证制度是国家司法制度的组成部分，是国家预防纠纷、维护法制、巩固法律秩序的一种司法手段。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/notarization" target="_blank">预约办理</router-link></a-button>
            <a-button><a href="https://api.egongzheng.com/thirdTransfer.htm?regionId=p28&appId=gs12348&version=v2&ticket=ST-168911-JFF7Sg5qqfrgowFJYsbT-cas01.example.org" target="_blank">在线办理</a></a-button>
          </div>
          <span class="business-banner-head-icon gz-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>公证服务指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="国内办理指南">
            <div role="tabpanel" class="tab-pane active" id="guide_1">
            <div class="ov_text">
              <h4>国内公证办理指南</h4>
              <ul>
                <li><a href="#blzn1">（一）继承公证</a></li>
                <li><a href="#blzn2">（二）赠与公证</a></li>
                <li><a href="#blzn3">（三）遗嘱、遗赠公证</a></li>
                <li><a href="#blzn4">（四）委托书公证</a></li>
                <li><a href="#blzn5">（五）房屋买卖公证</a></li>
                <li><a href="#blzn6">（六）声明书公证</a></li>
                <li><a href="#blzn7">（七）夫妻财产约定协议公证</a></li>
                <li><a href="#blzn8">（八）证据保全公证</a></li>
                <li><a href="#blzn9">（九）提存公证</a></li>
                <li><a href="#blzn10">（十）拆迁安置协议公证</a></li>
                <li><a href="#blzn11">（十一）贷款合同公证</a></li>
                <li><a href="#blzn12">（十二）抵押合同公证</a></li>
                <li><a href="#blzn13">（十三）招投标公证</a></li>
                <li><a href="#blzn14">（十四）拍卖公证</a></li>
              </ul>
              <a class="a_href" id="blzn1" style="margin-top: 30px;">
              <p>（一）继承公证</p>
              <p>1、申办继承公证应提交的材料；</p>
              <p>（1）被继承人的死亡证明（如医院的死亡通知书、骨灰证、火化证明、注销户口证明等）、身份证、户口簿等；</p>
              <p>（2）被继承人遗留的财产证明，如房地产证、存款凭证、股票等。如果遗产在香港的，须提供香港遗产税署出具的遗产清单；</p>
              <p>（3）继承人的身份证、户口簿、结婚证；</p>
              <p>（4）亲属关系证明（由被继承人档案所在单位人事部门出具意见并盖章；没有单位的，由社区居委会和街道办事处出具意见并盖章。因特殊情况无法提供的，可由继承人档案所在单位人事部门或社区居委会、街道办事处出具意见并盖章并提供相应证明）；</p>
              <p>（5）继承人放弃继承权的，必须由放弃人亲自到我处或居住地公证处办理弃权声明书公证；</p>
              <p>（6）其他继承人（除声明放弃继承的）因特殊情况不能亲自到公证处办理的，须提供经公证的委托书，委托其他继承人办理；</p>
              <p>（7）遗嘱继承人应提供合法、有效的遗嘱；</p>
              <p>（8）公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn2">
              <p>（二）赠与公证</p>
              <p>1、申办赠与公证赠与方应提交的材料；</p>
              <p>（1）身份证、户口簿或其他身份证明；</p>
              <p>（2）赠与方的婚姻状况证明；已婚的提供结婚证；未婚的提供未婚证明；</p>
              <p>（3）赠与财产的产权证明：如房屋应提交房屋产权证，其他财产应提交相应的产权凭证；</p>
              <p>（4）赠与财产为共有的，须取得其他共有人的一致同意方可赠与；</p>
              <p>（5）公证员认为应当提供的其他证明材料。</p>
              <p>2、申办赠与公证受赠方应提交的材料：</p>
              <p>（1）身份证、户口簿或其他身份证明；</p>
              <p>（2）受赠人为法人或其他组织的，应提交营业执照，法定代表人证明书；代理人代为办理的，应提交授权委托书；</p>
              <p>（3）受赠人为无民事行为能力或限制行为能力的，其代理人应提交有监护权的证明；</p>
              <p>（4）公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn3">
              <p>（三）遗嘱、遗赠公证</p>
              <p>申办遗嘱、遗赠公证应提供以下证明材料：</p>
              <p>1、立遗嘱(遗赠)人及遗嘱(遗赠)受益人的身份证明。例如：居民身份证、户口簿、护照等；如果是遗赠，而遗赠受益人是法人的，则应提供法人营业执照；</p>
              <p>2、遗嘱(遗赠)所处分的财产的所有权证明。如：房屋所有权证、存折、股票等；</p>
              <p>3、草拟的遗嘱。遗嘱必须由立遗嘱人亲笔书写，如本人不会书写或书写有困难的，可由公证员代书，立遗嘱人在上面签名、盖章；</p>
              <p>4、亲属关系证明。立遗嘱人需提供亲属关系证明，证明应记载立遗嘱人的婚姻状况及生育子女状况等；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn4">
              <p>（四）委托书公证</p>
              <p>申办委托书公证应提供以下证明材料：</p>
              <p>1、申请人的身份证明：个人的提交居民身份证或户口簿；法人单位提交单位营业执照、法定代表人证明书及其身份证；</p>
              <p>2、授权委托书的正文。授权委托书应当载明代理人的民名称、代理事项、权限和期限，有无转委托权等，并由委托人签名或盖章；</p>
              <p>3、委托授权的法律依据。如：委托代理人办理管理、处分房屋的，须提供房屋产权证书；委托诉讼的，须提交涉及诉讼的合同(协议)或法院的立案通知书；</p>
              <p>4、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn5">
              <p>（五）房屋买卖公证</p>
              <p>申办房屋买卖公证应提供以下证明材料：</p>
              <p>1、自然人申请提供身份证、户口簿（户籍证明）；如委托他人代办还应提供：有效的委托书、代理人的身份证明；</p>
              <p>法人申请提供：营业执照、外商投资企业批准证书； 公司章程； 法定代表人/负责人的身份证件（身份证、护照等）。如委托他人代办公证还应提供； 法定代表人/负责人有效的委托书；法人印章及法定代表人/负责人签名样式； 代理人的身份证明；</p>
              <p>2、《房屋所有权证》/《不动产权证书》； </p>
              <p>3、房屋产权档案查询单；</p>
              <p>4、如系共有房屋，需提供共有人身份证明（如身份证、户口簿、结婚证）及其同意出售房屋的证明； </p>
              <p>5、法律、法规、规章或公司章程中规定，转让必须经批准或通过的，须提供经批准或通过的文件；</p>
              <p>6、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn6">
              <p>（六）声明书公证</p>
              <p>申办声明书公证应提供以下证明材料：</p>
              <p>1、声明人是公民的，应提交身份证明，如身份证、户口簿或其他足以证明身份的证件（原件、复印件）。声明人是单位或企业法人的，应提交营业执照、法人身份证明书、法人身份证（原件、复印件）、股东（董事会）决议、章程等；</p>
              <p>2、声明书文本（我处可代为草拟文本）；</p>
              <p>3、与声明事项相关的凭证（如房地产证、股票、存款凭证等）；</p>
              <p>4、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn7">
              <p>（七）夫妻财产约定协议公证</p>
              <p>申办夫妻财产约定协议公证应提供以下证明材料：</p>
              <p>1、夫妻双方的身份证明，如身份证、户口簿等；</p>
              <p>2、结婚证或夫妻关系证明；</p>
              <p>3、与协议相关的财产有权、收益权等权益证明；</p>
              <p>4、夫妻财产约定协议文本（我处可代为草拟文本）；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a><a class="a_href" id="blzn8">
              <p>（八）证据保全公证</p>
              <p>申办证据保全公证应提供以下证明材料：</p>
              <p>1、申请人的身份证明：个人的提交居民身份证或户口簿；法人单位提交单位营业执照、法定代表人证明书及其身份证；代理人代为办理的，应提交授权委托书及其身份证；</p>
              <p>2、需要保全的证据，以及能够证明该证据可能灭失和当事人与灭失事实有法律上的利害关系的文件、资料；</p>
              <p>3、申请保全的证据是物证的，须提交物证样品或所有权证明或其它能够说明申请办理办理保全证据公证的有关证件；</p>
              <p>4、需要保全证人证言的，证人应亲自到场，并提交证人身份证明；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn9">
              <p>（九）提存公证</p>
              <p>申办提存公证应提供以下证明材料：</p>
              <p>1、申请人的身份证明：个人的提交居民身份证或户口簿；法人单位提交单位营业执照、法定代表人证明书及其身份证；代理人代为办理的，应提交授权委托书及其身份证；</p>
              <p>2、合同(协议)、担保书、赠与书、司法文书、行政决定等据以履行义务的依据；</p>
              <p>3、提存受领人姓名(名称)、地址、邮编、联系电话；</p>
              <p>4、提存标的物的种类、质量、数量、价值的明细表；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn10">
              <p>（十）拆迁安置协议公证</p>
              <p>申办拆迁安置协议公证应提供以下证明材料：</p>
              <p>1、申请人的身份证明（申请人为公司的，提交营业执照、法定代表人资格证明，申请人为个人的，提交身份证、户口簿、结婚证或未婚证明）；</p>
              <p>2、代理人代为申请的，应提交有效的授权委托书或受托人的身份证明；</p>
              <p>3、区、县以上人民政府房屋拆迁主管部门颁发的拆迁许可证明、文件；</p>
              <p>4、主管部门的拆迁公告；</p>
              <p>5、被拆迁房屋的产权证明；</p>
              <p>6、房屋拆迁安置协议书若干份；</p>
              <p>7、公证员认为应当提交的其他材料。</p>
              </a> <a class="a_href" id="blzn11">
              <p>（十一）贷款合同公证</p>
              <p>申办贷款合同公证应提供以下证明材料：</p>
              <p>1、法人资格证明及法定代表人身份证明和经营金融业务许可证。代理人代为申请的，委托代理人应提交《授权委托书》和代理人身份证；</p>
              <p>2、借贷合同文本；</p>
              <p>3、有担保方的，应提交担保文件；</p>
              <p>4、抵押贷款的，按合同份数提交抵押物清单及所有权或经营管理权证明；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn12">
              <p>（十二）抵押合同公证</p>
              <p>申办抵押合同公证应提供以下证明材料：</p>
              <p>1、企业法人营业执照（副本）原件及影印件；</p>
              <p>2、企业法定代表人身份证明书、身份证（原件、复印件）。证明书证明的法定代表人与营业执照记载的须一致；</p>
              <p>3、法定代表人不能亲自到公证处申请办理的，应提交法人《授权委托书》（授权书须由法定代表人亲自签署，并加盖企业公章）；</p>
              <p>4、受托人应提交身份证明，如身份证、户口簿或其他足以证明身份的证件（原件、复印件）；</p>
              <p>5、企业是有限责任公司或股份有限责任公司性质的，应提交有效的董事会决议（会议记录）、公司章程、抵押物产权证明；</p>
              <p>6、抵押登记证明；</p>
              <p>7、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="blzn13">
              <p>（十三）招投标公证</p>
              <p>申办招投标公证应提供以下证明材料：</p>
              <p>1、法人资格证明和法定代表人身份证。代为申请的，应提交《授权委托书》和本人身份证；</p>
              <p>2、申请人委托招标代理机构办理招标事宜的，应提交与招标代理机构的委托合同；</p>
              <p>3、有关主管部门对该招标项目和招标活动的批准文件；</p>
              <p>4、招标组织机构及组成人员名单；</p>
              <p>5、招标公告（通知）或招标邀请函；</p>
              <p>6、招标文件（主要包括招标说明书、投标人须知、招标项目技术要求、投标书格式、投标保证文件、合同条件等）；</p>
              <p>7、对投标人资格预审文件；</p>
              <p>8、评标组织机构及组成人员名单；</p>
              <p>9、委托招标的，受托人还须提交招标资格证明；</p>
              <p>10、公证员认为应当提供的其他证明材料。</p>
              </a><a class="a_href" id="blzn14">
              <p>（十四）拍卖公证</p>
              <p>申办拍卖公证应提供以下证明材料：</p>
              <p>1、拍卖方：</p>
              <p>（1）企业法人营业执照；</p>
              <p>（2）特种行业许可证；</p>
              <p>（3）法定代表人资格证明及身份证（原件、复印件）；</p>
              <p>（4）拍卖师的执业资格证书；</p>
              <p>（5）拍卖方与委托方签订的《委托拍卖合同》；</p>
              <p>（6）拍卖规则和拍卖公告；</p>
              <p>（7）公证员认为应当提供的其他证明材料。</p>
              <p>2、委托方:</p>
              <p>（1）委托方的主体资格证明：</p>
              <p>委托方是公民的，应提交身份证明，如身份证、户口簿或其他足以证明身份的证件（原件、复印件）；</p>
              <p>委托方是法人或其他组织的，应提交法人资格或其他组织的主体资格、法定代表人或负责人资格证明及身份证（原件、复印件）。</p>
              <p>（2）委托拍卖标的物清单和所有权证明或有处置权的批准文件。拍卖标的有共有人的，应提交共有人同意的证明及身份证（原件、复印件）；</p>
              <p>（3）拟委托拍卖标的物的评估报告；</p>
              <p>（4）企业主管部门委托拍卖的还应提交：</p>
              <p>①拟被拍卖企业破产的法律文书（如判决书、裁定书）或企业主管部门关于委托拍卖文件（批复）；</p>
              <p>②拍卖国有企业财产的，应提交国有资产管理局同意拍卖的文件（批复）；</p>
              <p>③拍卖集体企业财产的，应提交职工代表大会同意拍卖的决议；</p>
              <p>④拍卖股份制企业财产的，应提交股东大会决议。</p>
              <p>（5）行政机关委托拍卖的还应提交：</p>
              <p>①有关部门关于同意拍卖标的进入拍卖市场的文件（批复）；</p>
              <p>②具有罚没职能的机关出具的罚没清单；</p>
              <p>③人民法院委托拍卖的，应提交已经生效法律文书（如判决书、裁定书）；</p>
              <p>④税务机关委托拍卖的，应提交纳税人逾期未履行的限期催缴通知书和扣押物品清单；</p>
              <p>⑤委托拍卖文物的，应提交文物管理部门的批准文件及文物鉴定机构出具的鉴定文件。</p>
              <p>（6）公证员认为应当提供的其他证明材料。</p>
              <p>3、竞买方：</p>
              <p>（1）竞买方是公民的，应提交身份证明，如身份证、户口簿或其他足以证明身份的证件（原件、复印件）；</p>
              <p>（2）竞买方是法人或其他组织的，应提交法人资格或其他组织的主体资格证明、法定代表人或负责人资格证明及身份证（原件、复印件）；</p>
              <p>（3）拍卖方、委托方、竞买方委托他人代为办理公证的，应提交《授权委托书》和代理人的身份证（原件、复印件）；</p>
              <p>（4）公证员认为应当提供的其他证明材料。</p>
              </a>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="涉外办理指南">
            <div class="ov_text">
              <h4>涉外公证办理指南</h4>
              <ul>
                <li><a href="#swbzzn1">（一）出生公证</a></li>
                <li><a href="#swbzzn2">（二）亲属关系公证</a></li>
                <li><a href="#swbzzn3">（三）无犯罪记录公证</a></li>
                <li><a href="#swbzzn4">（四）证书（执照）公证</a></li>
                <li><a href="#swbzzn10">（五）印章、签名公证</a></li>
                <li><a href="#swbzzn6">（六）婚姻状况公证</a></li>
                <li><a href="#swbzzn7">（七）学历（学位）公证</a></li>
                <li><a href="#swbzzn8">（八）经历公证</a></li>
                <li><a href="#swbzzn9">（九）国籍公证</a></li>
                <li><a href="#swbzzn5">（十）文书文本相符公证/影印本与原本相符公证</a></li>
              </ul>
              <a class="a_href" id="swbzzn1" style="margin-top: 30px;">
              <p>（一）出生公证</p>
              <p>申办出生公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本、出生证或亲属关系证明（派出所、行政事业单位人事部或街道办事处出具）；已出境的应提交护照；</p>
              <p>2、护照照片（根据所需公证书份数提供）；</p>
              <p>3、当事人父母的身份证；</p>
              <p>4、代理人代为申请的，应当提交身份证；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn2">
              <p>（二）亲属关系公证</p>
              <p>申办亲属关系公证应提供以下证明材料：</p>
              <p>1、申请人的身份证、户口本、结婚证、亲属关系证明（派出所、行政事业单位人事部或街道办事处出具）；关系人已出境应提交护照；</p>
              <p>2、委托他人代办的，代理人需持委托书和身份证；</p>
              <p>3、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn3">
              <p>（三）无犯罪记录公证</p>
              <p>申办无犯罪记录公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本；</p>
              <p>2、当地公安部门出具的无犯罪记录的证明；</p>
              <p>3、委托他人代办的，代理人需持委托书和身份证；</p>
              <p>4、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn4">
              <p>（四）证书（执照）公证</p>
              <p>申办证书（执照）公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本；</p>
              <p>2、需公证的证书或执照的原件（结婚证、驾驶证、资格证、毕业证、营业执照、组织机构代码等）；</p>
              <p>3、公司为申请人的，需提供公司营业执照副本、组织机构代码证、法人身份证明书与身份证、授权书、受托人的身份证等材料；</p>
              <p>4、委托他人代理的，需提供身份证；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn10">
              <p>（五）印章、签名公证</p>
              <p>申办印章、签名公证应提供以下证明材料：</p>
              <p>1、当事人是法人的应提交法人资格证明、身份证、营业执照、组织机构代码证；</p>
              <p>2、当事人是自然人的，提交身份证、户口本；</p>
              <p>3、需公证的印章、签名文本原件；</p>
              <p>4、委托他人代办的，代理人需持委托书和身份证；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn6">
              <p>（六）婚姻状况公证</p>
              <p>1、未婚公证</p>
              <p>申办未婚公证应提供以下证明材料：</p>
              <p>（1）当事人的身份证、户口本或护照；</p>
              <p>（2）当事人户口所在地婚姻登记机关出具的未婚证明；</p>
              <p>（3）委托他人代办的，代理人需持委托书和身份证；</p>
              <p>（4）公证员认为应当提供的其他证明材料。</p>
              <p>2、结婚（已婚）公证、夫妻关系公证</p>
              <p>申办结婚（已婚）公证、夫妻关系公证应提供以下证明材料：</p>
              <p>（1）当事人的身份证、户口本或护照；</p>
              <p>（2）结婚证，若结婚证丢失，提供当事人户口所在地婚姻登记机关出具的婚姻关系证明；</p>
              <p>（3）当事人的2寸合影；</p>
              <p>（4）委托他人代办的，代理人需持委托书和身份证；</p>
              <p>（5）公证员认为应当提供的其他证明材料。</p>
              <p>3、离婚公证</p>
              <p>申办离婚公证应提供以下证明材料：</p>
              <p>（1）当事人的身份证、户口本或护照；</p>
              <p>（2）离婚证或人民法院已生效的判决书、调解书；</p>
              <p>（3）委托他人代办的，代理人需持委托书和身份证；</p>
              <p>（4）公证员认为应当提供的其他证明材料。</p>
              <p>4、未再婚公证</p>
              <p>申办未再婚公证应提供以下证明材料：</p>
              <p>（1）当事人的身份证、户口本或护照；</p>
              <p>（2）当事人户口所在地婚姻登记机关出具的无再婚婚姻登记记录证明；</p>
              <p>（3）委托他人代办的，代理人需持委托书和身份证；</p>
              <p>（4）公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn7">
              <p>（七）学历（学位）公证</p>
              <p>申办学历（学位）公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本；</p>
              <p>2、毕业证书、学位学位证书的原件；</p>
              <p>3、委托他人代办的应出具委托书和身份证；</p>
              <p>4、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn8">
              <p>（八）经历公证</p>
              <p>申办经历公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本；</p>
              <p>2、当事人所在单位人事部门出具的工作经历证明；当事人无工作单位的，由其住所地街道办或乡、镇人民政府出具证明；在校学习的，由所在学校出具证明；</p>
              <p>3、与工作经历有关的职业资格证书、职务证明；</p>
              <p>4、委托他人代办的，代理人需持委托书和身份证；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn9">
              <p>（九）国籍公证</p>
              <p>申办国籍公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本、护照等，已注销户口的，提交所在地公安机关出具的户籍记载情况证明；</p>
              <p>2、户口所在地公安机关出具的国籍证明；</p>
              <p>3、代理人代为申请的，应提交委托书和身份证；</p>
              <p>4、公证员认为应当提供的其他证明材料。</p>
              </a> <a class="a_href" id="swbzzn5">
              <p>（十）文书文本相符公证/影印本与原本相符公证</p>
              <p>申办文书文本相符公证/影印本与原本相符公证应提供以下证明材料：</p>
              <p>1、当事人的身份证、户口本；</p>
              <p>2、需公证的文件的原件（在职证明、收入证明、成绩单等）；</p>
              <p>3、公司为申请人的，需提供公司营业执照副本、组织机构代码证、法人身份证明书与身份证、授权书、受托人的身份证等材料；</p>
              <p>4、委托他人代理的，需提供身份证；</p>
              <p>5、公证员认为应当提供的其他证明材料。</p>
              </a>
              </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="公证收费">
            <div class="ov_text">
              <p>一、证明法律行为</p>
              <p>（一）证明合同、协议</p>
              <p>1.证明合同：标的额50万元（含）以下的部分，按0.25%收取；超过50万元不满500万元（含）的部分，按0.2%收取；超过500万元不满5000万元（含）的部分，按0.1%收取；超过5000万元不满1亿元（含）的部分，按0.05%收取；超过1亿元的部分，按0.01%收取。</p>
              <p>2.证明夫妻（婚前）财产约定、涉及财产分割的离婚协议、遗产分割协议、共有财产约定和分割协议，以及其他以财产分割为内容的协议，每件收取500元。</p>
              <p>3.证明离婚、抚养、赡养、监护、劳动（劳务）、寄养、遗赠扶养、解除收养关系、出国留学等协议，以及其他不涉及财产关系的协议，每件收费200元。</p>
              <p>（二）赋予债权文书具有强制执行效力：按照证明合同（协议）收费标准并加收20%的费用执行。</p>
              <p>（三）证明遗嘱，每件收取500元。</p>
              <p>（四）证明委托、声明、保证、认领亲子，每件收取200元。</p>
              <p>（五）证明财产继承、接受遗赠、赠与，按受益额比率收费：</p>
              <p>20万元（含）以下的按不超过1.2％收取；超过20万元不满50万元（含）的部分，按不超过1％收取；50万元不满500万元（含）的部分，按不超过0.8％收取；超过500万元不满1000万元（含）的部分，按不超过0.5％收取。超过1000万元的部分，按不超过0.1%收取。证明单方赠与受赠的，减半收取。</p>
              <p>（六） 证明招标投标、拍卖等竞争性交易行为：标的额1000万元（含）以下的部分，每件收取2500元；超过1000万元不满5000万元（含）的部分，按0.01%收取；超过5000万元以上的部分，按0.005%收取。</p>
              <p>（七）证明开奖、抽签、公司会议、订立公司章程等现场监督类公证事项，每件收取2000元。</p>
              <p></p>
              <p><span style="font-size: 12pt; color: rgb(0, 0, 0); line-height: 2em;" data-mce-style="font-size: 12pt; color: #000000; line-height: 2em;">二、证明有法律意义的事实</span></p>
              <p>（一）证明出生、生存、死亡、国籍、户籍注销、曾用名、住所地（居住地）、学历、学位、经历、职务（职称）、资格、无（有）违法犯罪记录、婚姻状况、亲属关系、财产权、收入状况、纳税状况、选票、指纹等有法律意义的事实，每件收取150元。</p>
              <p>（二）证明不可抗力、意外事件、监护权、收养关系、抚养事实、用于继承的亲属关系、票据拒绝、查无档案记载，每件收取300元。</p>
              <p>（三）保全证据</p>
              <p>1.保全证人证言、书证、当事人陈述，200元／小时收取，不足30分钟的减半收取，超过30分钟不足1小时的按1小时计算。</p>
              <p>2.保全物证、视听资料，600元／小时收取，不足30分钟的减半收取，超过30分钟不足1小时的按1小时计算。</p>
              <p>3.保全行为过程和事实，800元／小时收取，不足30分钟的减半收取，超过30分钟不足1小时的按1小时计算。</p>
              <p>4.保全电子数据，1000元／小时收取，不足30分钟的减半收取，超过30分钟不足1小时的按1小时计算。</p>
              <p></p>
              <p>三、证明有法律意义的文书</p>
              <p>（一）证明证书、执照，每件收取150元。</p>
              <p>（二）证明文书上的签名、印鉴、日期，每件收取150元。</p>
              <p>（三）证明文书的副本、影印本与原本相符，每件收取80元。</p>
              <p>（四）当事人办理涉外公证时，要求同时证明该公证书的译文与原文相符的，按照证明文书的副本、影印本与原本相符收费标准减半收取。</p>
              <p></p>
              <p>四、依法办理的其他事务</p>
              <p>（一）抵押登记，登记额10万元（含）以下的，每件收费100元；10万－100万元（含），每件收费200元；100万元－300万元（含），每件收费300元；300万－500万元（含），每件收费500元；500万元以上，每件收费800元。</p>
              <p>（二）提存，按标的额的0.3％计收，最低收取100元。代申请人支付的保管费另收。</p>
              <p>（三）保管遗嘱，每件每年收费30元；保管与公证事项有关的物品、文书、票据、凭证、有价证券等，每件每年收费10元。</p>
              <p>（四）代写与公证事项有关的文书：合同（协议）每件收费300元，其他文书每件100元。</p>
              <p>（五）代办登记、认证，每件收取60元。</p>
              <p>（六）提供公证法律咨询，每次30元。</p>
              <p></p>
              <p>五、担任公证法律顾问、出具法律意见书， 由申请人和公证机构协商收取费用。</p>
              <p></p>
              <p>六、翻译费，英文翻译、打印全套服务，每千字符60元，不足一千字符按一千字符计算。</p>
              <p></p>
              <p>七、公证书副本费，每份10元，有译文的加收10元。</p>
              <p></p>
              <p>八、查询公证档案，每卷每次30元。</p>
              <p></p>
              <p>九、未规定收费标准的其他公证事项和法律事务，比照最相类似的项目收费，无法比照的，由申请人与公证机构协商确定费用。</p>
            </div>
          </a-tab-pane>
        </a-tabs>
        </div>
        <Notary></Notary>
        <div class="mt-5 overflow-hidden tab">
          <div class="col-lg-6 col-md-6 col-xs-6 float-left pl-0">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="公证动态">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176891df0ed8a8381287688095907b4' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="公示公告">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176993a856a8a838284769932e50006' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="办事表格下载">
                <div  class="tab-pane">
              <Collection  name="post" :query="{ target: '0176995f08a58a838284769932e50125' }"
                v-slot="{ collection }"  :limit=9 >
              <Article :refObj="collection"></Article>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 float-right p-0 pl-4">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="公证案件">
                <div  class="tab-pane">
              <Collection  name="case" :query="{ recase_type:'公证工作' }"
                v-slot="{ collection }"  :limit=9 >
              <Case :refObj="collection"></Case>
              </collection>
              </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="政策法规">
                <div  class="tab-pane">
              <Collection  name="law" :query="{ law_ctype:'公证案例' }"
                v-slot="{ collection }"  :limit=9 >
              <Law :refObj="collection"></Law>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>

        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="公证机构">
              <div class="screen_box">
                <City :value= providerQuery.provider_type @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  business_type: providerQuery.professional,
                  provider_name: providerQuery.provider_name,
                  provider_type: providerQuery.provider_type
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="公证员">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: '公证人员'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import Article from '../../components/base/Article.vue'
import Case from '../../components/base/Case.vue'
import Law from '../../components/base/Law.vue'
import Notary from '@/components/business/Notary'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    Article,
    Notary,
    Case,
    Law
  },
  data () {
    return {
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        professional: '',
        provider_name: '',
        person_name: '',
        provider_type: '公证机构'
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
      this.providerQuery.professional = obj.professional
    }
  }
}
</script>
